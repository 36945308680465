<template>
	<v-app>
		<v-row style="margin-top: 0; margin-bottom: 0;">
			<v-flex  md12 lg6 xl6 class="row1 hidden-md-and-down d-flex justify-center"
				style="background-image: linear-gradient(to right top, rgba(100, 115, 201, 0.7), rgba(25, 32, 72, 0.7)), url(https://picsum.photos/1920/1080?random); background-position: center center;">
				<h1 class="px-16 mx-5 text-center">Балабақшаландыру жүйесіне қош келдіңіз!</h1>
			</v-flex>
			<v-flex xs12 sm-12 md12 lg6 xl6 >     
				<v-container class="login-page mt-10">
					<v-row>
						<v-flex xs6 sm6 md6 lg6 xl6>
						Place for logo
						</v-flex>
					</v-row> 
					<v-row>
						<v-btn-toggle
							tile
							v-model="loginType"
							group>
							<v-btn value="department">Гороно</v-btn>
							<v-btn value="parent">Ата-ана</v-btn>
							<v-btn value="kindergarten">Балабақша</v-btn>
						</v-btn-toggle>
					</v-row>
					<v-layout align-center justify-center>  
						<v-flex xs10 sm8 md10 lg10>
							<v-form class="form" ref="form" @submit.prevent="handleSubmit">
								<v-file-input
									color="indigo lighten-1"
									label="Выберите файл"
									accept=".p12"
									:rules="[v => !!v || 'Eds is required']"
									@change="toBase64"
									>
								</v-file-input>
								<v-text-field
									color="indigo lighten-1"
									label="Пароль"
									type="password"
									prepend-icon="mdi-key"
									v-model="ecpPassword"
									:rules="[v => !!v || 'Password is required']"
								></v-text-field>

								<v-flex class="d-flex justify-end">
									<v-btn 
										height = 50px
										dark color="indigo lighten-1"
										class="login mt-3"
										type="submit"
										:loading="loading">
									Login
									</v-btn> 
								</v-flex>
							</v-form>
						</v-flex> 
					</v-layout>  
				</v-container>
			</v-flex>  
		</v-row>
	</v-app>
</template>

<script>
	export default {
		computed: {
			selectedLanguage() {
				return this.$store.state.language;
			}
		},

		data: () => ({
			ecpFileData: '',
			ecpPassword: '',
			lang: null,
			loginType: 'department',
			loading: false
		}),
		methods: {
			async handleSubmit() {
				if(this.$refs.form.validate()) {
					this.loading = true;
					await this.$http.post('/login/'+this.loginType, {
						"p12": this.ecpFileData,
						"password": this.ecpPassword
					}).then((response) => {
						this.loading = false;
						this.$store.commit('setCurrentRole', this.loginType);
						this.$store.dispatch('login', response).then(() => {
							this.$router.push({ path: '/'+this.loginType });
						});
					}).catch(() => this.loading = false);
				}
			},
			toBase64(file) {
				if(file) {
					const reader = new FileReader();
					reader.readAsDataURL(file);
					reader.onload = () => {
						let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
						if ((encoded.length % 4) > 0) {
							encoded += '='.repeat(4 - (encoded.length % 4));
						}
						this.ecpFileData = encoded;
					};
				}
			}
		}
	};
</script>
<style scoped>
	.login-page {
		padding: 10%;
	}
	.login-page .login-logo {
		width: 50px;
		margin-bottom: 100px;
	}
	.login-page .signup, .login-page .login {
		border-radius: 5px;
	}
	.login-page .login {
		position: relative;
		top: 10px;
		width: 120px;
	}
	.login-page .form {
		top: 50px;
		position: relative;
	}
	.login-page .signup {
		width: 120px;
	}
	.row1 h1 {
		position: absolute;
		z-index: 1;
		color: white;
		top: 40%;
		font-family: 'ROBOTO';
		font-size: 56px;
	}
</style>

<!-- <template>
<div>
	Страница авторизации
	<br>
	<v-btn to="/dashboard" text>Облано</v-btn> 
	<v-btn to="/control" text>Гороно</v-btn> 
	<v-btn to="/kindergarten" text>Бала бақша</v-btn> 
	<v-btn to="/applicant" text>Ата-ана</v-btn> 
</div>
</template> -->
