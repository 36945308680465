import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Auth from '../views/login/Auth.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Auth,
    beforeEnter(to, from, next) {
      if(store.getters.getAccessToken && store.getters.getCurrentRole) {
        next(store.getters.getCurrentRole);
      }
      next();
    }
  },
  {
    path: '/',
    name: 'Home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Home,
    /*children: [
      {
        path: 'control',
        name: 'Control',
        component: () => import('../views/control/Control.vue'),
      }
    ]*/
  },
  {
    path: '/department',
    component: () => import('../views/department/Department.vue'),
    children: [
      {
        path: '',
        name: 'Department',
        component: () => import('../views/department/RequestList.vue'),
      },
      {
        path: 'request/:id',
        component: () => import('../views/department/RequestView.vue'),
      },
      {
        path: 'kindergarten', // TODO
        component: () => import('../views/department/KindergartenList.vue'),
      },
      {
        path: 'kindergarten/:id', // TODO
        component: () => import('../views/department/KindergartenView.vue'),
      },
      {
        path: 'kindergarten/:id/group/:groupId', // TODO
        component: () => import('../views/department/KindergartenGroupView.vue'),
      }
    ],
    beforeEnter(to, from, next) {
      store.dispatch('changeBaseUrl');
      next();
    }
  },
  {
    path: '/kindergarten',
    component: () => import('../views/kindergarten/KindergartenLayout.vue'),
    children: [
      {
        path: '',
        name: 'Kindergarten',
        component: () => import('../views/kindergarten/DocRequestList.vue'),
      },
      {
        path: 'doc-request/:id',
        component: () => import('../views/kindergarten/DocRequestView.vue'),
      },
      {
        path: 'groups',
        component: () => import('../views/kindergarten/GroupList.vue'),
      },
      {
        path: 'group/:id',
        component: () => import('../views/kindergarten/GroupView.vue'),
      },
      {
        path: 'vacancy',
        component: () => import('../views/kindergarten/AddVacancy.vue'),
      },
    ],
    beforeEnter(to, from, next) {
      store.dispatch('changeBaseUrl');
      next();
    }
  },
  {
    path: '/parent',
    component: () => import('../views/parent/Parent.vue'),
    children: [
      {
        path: '',
        alias: 'news',
        name: 'Parent',
        component: () => import('../views/parent/News.vue'),
      },
      {
        path: 'queue-requests',
        component: () => import('../views/parent/QueueRequestList.vue'),
      },
      {
        path: 'queue-request/:id',
        component: () => import('../views/parent/QueueRequestView.vue'),
      },
      {
        path: 'children',
        component: () => import('../views/parent/ChildrenList.vue'),
      },
      {
        path: 'profile',
        component: () => import('../views/parent/Profile.vue'),
      },
      {
        path: 'auction',
        component: () => import('../views/parent/AuctionList.vue'),
      },
      /*
      {
        path: 'ddo-requests',
        component: () => import('../views/parent/DdoRequestList.vue'),
      },
      {
        path: 'ddo-request/:id',
        component: () => import('../views/parent/DdoRequestView.vue'),
      },*/
    ],
    beforeEnter(to, from, next) {
      store.dispatch('changeBaseUrl');
      next();
    }
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if(to.name != 'Login' && !store.getters.getAccessToken) {
    next("/login");
  }
  next();
});

export default router
